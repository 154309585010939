const apiUrl = process.env.REACT_APP_WORKER_URL
const handledRequest = async (endpoint, init = {}) => {
    const req = new Request(apiUrl + endpoint, init)
    const response = await fetch(req, init)
    const isSuccessful = response.ok
    const json = await response.json()
    if (isSuccessful) return json
    return Promise.reject(json)
}
export const WorkerService = {
    initWorkshopPayment(body) {
        return handledRequest('create-transaction', {body: JSON.stringify(body), method: 'POST'})
    },
    async getWorkshops() {
        return handledRequest('get-workshops')
    }
}