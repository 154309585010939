import {Container} from "../common/Container";
import {TextContainer} from "../common/TextContainer";
import {useEffect, useState} from "react";
import WorkshopItem from "./WorkshopItem";
import {WorkerService} from "../../services/WorkerService";

const UpcomingActivities = () => {
    const [workshops, setWorkshops] = useState([])
    useEffect(() => {
        WorkerService.getWorkshops().then(setWorkshops)
    }, [])
    return <Container>
        <TextContainer>
            <h2>Próximas actividades</h2>
            {workshops.map((workshopData) => <WorkshopItem data={workshopData} key={workshopData[5]}/> )}
        </TextContainer>
    </Container>
}

export default UpcomingActivities