import styled from "styled-components";
import {useRef, useState} from "react";
import RegistrationForm from "./RegistrationForm";

const WorkshopContainer = styled.div`
  background-color: #f5f5f5;
  text-align: center;
  transition: background-color .3s;
  white-space: pre-line;
  &:hover {
    background-color: #fff0f0;
  }
  
  > p.workshop-title {
    padding: 8px;
    font-size: 21px;
    font-weight: bold;
    user-select: none;
    cursor: pointer;

  }
  
  & .workshop-register {
    border-radius: 19px;
    background-color: antiquewhite;
    border: 1px solid wheat;
    padding: 8px 15px;
    height: 38px;
    margin: 15px;
  }
`

const Collapsible = styled.div`
  transition: opacity .1s, height .2s;
  height: ${({collapsed, heightNum}) => collapsed ? '0' : heightNum}px;
  opacity: ${({collapsed}) => collapsed ? '0' : '1'};
`

const WorkshopItem = ({data: [title, description, price, location, date, schedule]}) => {
    const [collapsed, setCollapsed] = useState(true)
    const [showForm, setShowForm] = useState(false)
    const collapseRef = useRef(null)
    const handleRegister = () => {
        setShowForm((prev) => !prev)
    }

    const handleCollapse = (e) => {
        if (e.target.classList.contains('workshop-title')) setCollapsed(prev => !prev)
    }

    return <WorkshopContainer onClick={handleCollapse}>
        <p className='workshop-title' >{title} - {new Date(date).toLocaleDateString('es-CL')}</p>
        <Collapsible collapsed={collapsed} heightNum={collapseRef.current && collapseRef.current.scrollHeight} ref={collapseRef}>
            <p className="workshop-description">
                {description}
            </p>
            <p className="workshop-price">Valor: {new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(price)}</p>
            <p className="workshop-location">Lugar: {location}</p>
            {schedule && <p className="workshop-schedule">Itinerario: {schedule}</p>}
            <button className="workshop-register" onClick={handleRegister}>{showForm ? 'Volver':'Inscribirme'}</button>

        </Collapsible>
        {showForm && !collapsed && <RegistrationForm workshopCode={btoa(`${date}${title}`)} price={price} />}
    </WorkshopContainer>
}

export default WorkshopItem