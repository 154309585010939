import styled from "styled-components";
import TextUnwrapper from "../common/TextUnwrapper";
import {Container} from "../common/Container";
import {TextContainer} from "../common/TextContainer";

const ImageTitle = styled.div`
  width: 100%;
  height: 50vh;
  position: relative;
  background-color: lightgray;
  background-image: url(${({ imgUrl }) => imgUrl});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  > h1 {
    color: white;
    position: absolute;
    bottom: 10%;
    left: 10%;
    text-shadow: 2px 2px 2px #333;
  }
`;

const Section = ({ imgUrl, title, label, wrappedText }) => {
  return (
    <Container>
      <ImageTitle imgUrl={imgUrl}>
        <h1>{title || label}</h1>
      </ImageTitle>
      <TextContainer>
        <TextUnwrapper wrappedText={wrappedText} />
      </TextContainer>
    </Container>
  );
};

export default Section;
