import {ErrorMessage, Field, Form, Formik} from 'formik';
import {object, setLocale, string} from 'yup';
import styled from "styled-components";
import {WorkerService} from "../../services/WorkerService";
import webpaylogo from '../assets/images/webpaylogo.png'

const FormContainer = styled.div`


  > form {
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
      margin-bottom: 20px;
    }
    > label {
      margin: 5px 0;
      min-width: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > input {
        border: 1px solid #f3f3f3;
        height: 40px;
        padding: 0 10px;
        margin: 0 10px;
        border-radius: 10px;
      }
    }

    > button {
      border-radius: 19px;
      background-color: lightgreen;
      border: 1px solid wheat;
      padding: 8px 15px;
      height: 38px;
      margin: 15px;
      cursor: pointer;
    }

    > div.form-error {
      color: red;
      font-size: 14px;
    }
  }

`

setLocale({
    string: {
        email: 'Debe ser un email válido'
    },
    mixed: {
        required: 'Campo requerido',
    }
});

let userSchema = object().shape({
    name: string().required(),
    lastName: string().required(),
    email: string().email().required(),
    phone: string().required(),
    country: string().required(),
});
const RegistrationForm = ({workshopCode, price}) => (
    <FormContainer>
        <Formik
            initialValues={{email: '', name: '', lastName: '', phone: '', country: ''}}
            validationSchema={userSchema}
            onSubmit={(values, {setSubmitting}) => {
                WorkerService.initWorkshopPayment({...values, workshopCode, price})
                    .then(res => window.location.href = `${res.url}?token_ws=${res.token}`)
                    .catch(console.warn)
                    .finally(() => {
                    setSubmitting(false)
                })
            }}
        >
            {({isSubmitting}) => (
                <Form>
                    <label>Email
                        <Field type="email" name="email"/>
                    </label>
                    <ErrorMessage name="email" component="div" className='form-error'/>

                    <label>Nombre
                        <Field type="text" name="name"/>
                    </label>
                    <ErrorMessage name="name" component="div" className='form-error'/>

                    <label>Apellido
                        <Field type="text" name="lastName"/>
                    </label>
                    <ErrorMessage name="lastName" component="div" className='form-error'/>

                    <label>Teléfono
                        <Field type="text" name="phone"/>
                    </label>
                    <ErrorMessage name="phone" component="div" className='form-error'/>

                    <label>País de residencia
                        <Field type="text" name="country"/>
                    </label>
                    <ErrorMessage name="country" component="div" className='form-error'/>

                    <button type="submit" disabled={isSubmitting}>
                        Pago con Webpay
                    </button>
                    <img src={webpaylogo} alt='webpay-plus logo'/>
                </Form>
            )}
        </Formik>
    </FormContainer>
);

export default RegistrationForm;