import metodoainardi from "../components/assets/images/metodoainardi.png";
import aboutMe from "../components/assets/images/about-me.jpg";
import {
  aboutMeText,
  ainardiMethodText
} from "./sectionsTextContent";

export const sections = [
  { label: "Inicio", path: "/", noRoute: true },
  {
    label: "Método Ainardi",
    path: "/metodo-ainardi",
    imgUrl: metodoainardi,
    wrappedText: ainardiMethodText,
  },

  {
    label: "Sobre mí",
    path: "/sobre-mi",
    imgUrl: aboutMe,
    wrappedText: aboutMeText,
  },
];
