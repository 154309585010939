export const ainardiMethodText = [
  {
    type: "plain",
    tag: "p",
    content:
      "Mi acompañamiento terapéutico esta orientado a revisar los traumas de la vida, la memoria fetal y el transgeneracional.",
  },
  {
    type: "plain",
    tag: "p",
    content:
      "Una práctica terapéutica que se enfoca en entender cómo las experiencias traumáticas de la vida, incluyendo aquellas que ocurrieron durante la gestación y las que se transmiten de generación en generación, pueden afectar la salud mental y emocional de una persona.",
  },
  {
    type: "plain",
    tag: "p",
    content: "La memoria fetal se refiere a la capacidad de los seres humanos para grabar a nivel inconsciente las experiencias emocionales que vivió nuestra madre en nuestro periodo de gestación y embarazo",
  },

  {
    type: "plain",
    tag: "p",
    content:
      "Además, se ha descubierto que los traumas pueden transmitirse de generación en generación a través de patrones de comportamiento, creencias y actitudes que se aprenden de nuestros padres, abuelos y bisabuelos, teniendo un impacto significativo en la salud mental y emocional de las personas. Esto es lo que conocemos como memoria transgeneracional",
  },
  {
    type: "plain",
    tag: "p",
    content: "Mi terapia se apoya en la investigación personal como también en el estudio y la experiencia de númerosos investigadores como: El Dr. Geerd Hamer, Anne Schutzenberger, Marc Fréchet, Groddeck y Claude Sabbah, Alejandro Jodorowsky entre otros, que han demostrado que las enfermedades no existen como tales sino que se trata de programas biológicos cargados de sentido.",
  },
];

export const aboutMeText = [
  {
    type: "plain",
    tag: "p",
    content:
      "Mi camino en la terapia empezó el año 2006 principalmente por una búsqueda personal de entendimiento y sanación.",
  },
  {
    type: "plain",
    tag: "p",
    content:
      'Comencé de forma muy dedicada a estudiar Biomagnetismo (Dr. Goiz),Flores de Bach y Reiki. Fue dentro de este periodo que empecé a atender consultantes. Mi interés por la terapia fue cada vez más intenso y así fue que el 2008 viajé a Cuba y conocí a la Dra Gisela Abdon quien me mostró y enseñó las esencias florales de Cuba, "sistema arcoiris". Mi trayectoria continuo al ingresar estudiar en el Instituto Español de Bioneuroemoción. (Perú, Cuba).',
  },
  {
    type: "plain",
    tag: "p",
    content:
      "Luego de terminar esta apasionante metodología, seguí mi aprendizaje en Psicosomática Clínica y Humanista con el Dr Salomón Sellam (Perú, España). Esto me llevó a estudiar la Nueva Medicina Germánica con el Dr. Luis Felipe Espinosa y el Dr Eduardo Castellanos y la Dra Maria Teresa Ilari en Chile.",
  },
  {
    type: "plain",
    tag: "p",
    content:
      "Cuando toda esta nueva visión se desplegó ante mi, se me hizo imprescindible estudiar Psicomagia y Psicogenealogía con Gabriela Rodríguez y Psicoterapia con un enfoque holístico con la Psiquiatra Adriana (Nana) Schnake también Chile.",
  },
  {
    type: "plain",
    tag: "p",
    content:
      "Hoy sigo estudiando, atendiendo en mi consulta particular y haciendo talleres grupales de forma periódica, apasionandome cada vez más en este maravilloso camino en el que he aprendido que todas las respuestas para sanar están dentro de uno, solo necesitas a tu lado un terapeuta neutro que te acompañe.",
  },
];
