import styled from "styled-components";
import {SM_BREAKPOINT} from "../../utils/constants";

export const TextContainer = styled.div`
  padding: 24px;

  p {
    font-size: 18px;
  }

  li {
    font-size: 18px;
    margin-bottom: 8px;
  }

  .emphasized {
    font-style: italic;
  }

  @media (max-width: ${SM_BREAKPOINT}) {
    padding: 14px;
  }
`;